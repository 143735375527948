.slider-block {
    margin-top: 100px;
}

.slider-block_title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 60px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.03em;

    color: #FFFFFF;
    margin-bottom: 110px;
}

.slider-block_slide {

}

.slider-number_numb {
    width: 1060px;
    position: relative;
    margin: -12px auto 0;
}

.slider-number_numb p {
    position: absolute;
    left: 50%;
    top: -14px;
    transform: translate(-50%, 0);

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 60px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.03em;

    color: #A089CA;
}

.slider-block_slide-number {
    display: flex;
    align-items: center;
}

.icon-arrow-left {
    cursor: pointer;
}

.icon-arrow-right {
    cursor: pointer;
}

.slider-block_slide-item {
    margin-top: 90px;
}

@media (max-width: 1240px) {
    .slider-block_slide-item {
        display: none;
    }
}

.slide-dots {
    display: flex;
    justify-content: center;
}

.dots-item {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid transparent;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    cursor: pointer;
}

.dots-item:last-child {
    margin-right: 0;
}

.active {
    border: 1px solid #A089CA;
}

.dots-item div {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background: #A089CA;
}

.slider-item-relative {
    position: relative;
    min-height: 617px;
    height: 100%;
}

.slider-item-relative p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    /* or 144% */

    text-align: center;
    letter-spacing: 0.03em;

    color: #FFFFFF;
}

.slider-text-1 {
    width: 293px;
    position: absolute;
    left: 19px;
    top: 167px;
}

.slider-text-2 {
    width: 342px;
    position: absolute;
    top: 280px;
    left: 430px;
}

.slider-text-3 {
    width: 362px;
    position: absolute;
    right: 50px;
    bottom: 38px;
}

.slider-item-relative img {
    width: 100%;
}

.slider-block_slide-item h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    /* or 108% */

    text-align: center;
    letter-spacing: 0.03em;

    color: #FFFFFF;
    margin-bottom: 40px;
}

.icon-num {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: -81px;
}

.icon-line-left {

}

@media (max-width: 1240px) {
    .icon-line-left {
        position: absolute;
        left: 0;
        top: 14px;
        transform: rotate(
                180deg
        );
    }

    .icon-line-right {
        position: absolute;
        right: 0;
        top: 14px;
        transform: rotate(
                180deg
        );
    }

    .icon-num {
        z-index: 99;
    }

    .slider-number_numb p {
        z-index: 999;
    }

    .icon-arrow-left {
        margin-top: 14px;
    }

    .icon-arrow-right {
        margin-top: 14px;
    }
}

.icon-line-right {

}

@media (max-width: 1014px) {
    .slider-block_title {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 40px;
        /* or 133% */

        text-align: center;
        letter-spacing: 0.03em;

        color: #FFFFFF;
    }
}