.faq {
    margin-top: -320px;
    position: relative;
    z-index: 999;
}

@media (max-width: 1270px) {
    .faq {
        margin-top: -140px;
    }
}

.faq_content h2 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin-bottom: 40px;
}

.faq .MuiPaper-root {
    background: linear-gradient(90deg, #1A3143 0%, rgba(26, 49, 67, 0) 100%), #142634;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    margin-bottom: 20px;
}

.faq .MuiAccordion-rounded {
    border-radius: 10px !important;
}

.faq .MuiTypography-root {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.faq .MuiIconButton-label {
    background: linear-gradient(90deg, #1A3143 0%, rgba(26, 49, 67, 0) 100%), #142634;
    border-radius: 10px;
    width: 60px;
    height: 60px;
}

.faq .MuiIconButton-edgeEnd {
    padding: 0;
}

.faq .MuiSvgIcon-root {
    fill: #A089CA;
    width: 80px;
    height: 40px;
}

.faq .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: none!important;
}

.faq .MuiAccordionSummary-expandIcon.Mui-expanded .MuiSvgIcon-root {
    transform: rotate(180deg);
}

.faq-accordion-desc {
    font-size: 18px !important;
    font-weight: 400 !important;
}