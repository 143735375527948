.start-block {
    position: relative;
    z-index: 999;
}

.start-block_mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.start-block_circle {
    position: relative;
    width: 675px;
    height: 695px;
    margin: 0 auto;
    background: url("./assets/7.png") center no-repeat;
    background-size: contain;
    display: flex;
}

.start-block_circle-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -35px;
}

.start-block_circle-content h3 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin-bottom: 30px;
}

.start-block_circle-content button {
    height: 50px;
}

.start-block_circle p {
    position: absolute;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.start-text-1 {
    width: 408px;
    text-align: center;
    left: 130px;
    top: -50px;
}

.start-text-2 {
    width: 346px;
    right: -265px;
    top: 157px;
}

.start-text-3 {
    width: 346px;
    right: -265px;
    top: 380px;
}

.start-text-4 {
    width: 391px;
    top: 380px;
    left: -310px;
    text-align: right;
}

.start-text-5 {
    left: -310px;
    top: 140px;
    width: 391px;
    text-align: right;
}