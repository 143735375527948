.item-start-mobile {
    position: relative;
    width: 230px;
    text-align: center;
    margin-bottom: 170px;
}

.item-start-mobile:last-child {
    margin-bottom: 0;
}

.item-start-mobile_img {
    position: absolute;
    top: -160px;
    left: 50%;
    transform: translate(-50%, 0);
}

.item-start-mobile_img img {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}

.item-start-mobile_img span {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 60px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.03em;

    color: #A089CA;
    position: absolute;
    left: 50%;
    top: 60px;
    transform: translate(-50%, 0);
}

.item-start-mobile p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    text-align: center;
    letter-spacing: 0.03em;

    color: #FFFFFF;
}