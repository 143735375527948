.control-pamm {
    position: relative;
    width: 100%;
    height: 1420px;
    background: url("./assets/9.png") center -20px no-repeat;
    background-size: auto;
    margin-top: -160px;
}

@media (max-width: 1270px) {
    .control-pamm {
        margin-top: 120px;
        height: 100%;
        padding-bottom: 210px;
        background: url('./assets/9.png') center 760px no-repeat !important;
    }
}

.control-mobile {
    display: none;
}

@media (max-width: 1270px) {
    .control-mobile {
        display: block;
    }
}

.control-pamm_icon2 {
    width: 1270px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: calc((100% - 4140px)/2 + 10px);
}

@media (max-width: 425px) {
    .control-pamm_icon2 {
        top: calc((100% - 6180px)/2 + 10px);
    }
}

.control-mobile h2 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    /* or 133% */

    text-align: center;
    letter-spacing: 0.03em;

    color: #FFFFFF;
}

.control-pamm_img {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    top: 0;
}

.control-title {
    position: absolute;
    width: 725px;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 50%;
    transform: translate(-50%, 0);
    top: 290px;
}

.control-title h2 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin-bottom: 30px;
}

.control-title button {
    height: 50px;
    text-transform: uppercase;
}

.control-texts {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.control-texts p {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    position: absolute;
    width: 299px;
}

.control-texts-1 {
    top: -275px;
    left: 25px;
}
.control-texts-2 {
    top: -70px;
    left: 267px;
}
.control-texts-3 {
    left: 514px;
    top: 130px;
}
.control-texts-4 {
    left: 755px;
    top: -70px;
}
.control-texts-5 {
    left: 1005px;
    top: -270px;
}
