.why-block {
    margin-top: 90px;
    width: 100%;
    position: relative;
    margin-bottom: 150px;
}

@media (max-width: 1270px) {
    .why-block {
        margin-bottom: 210px;
    }
}

.why-blocks {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(360px,1fr));
    gap: 25px;
}

.why-blocks_item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 375px;
    background: #142634;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
    border: 1px solid #a089ca;
    border-radius: 30px;
}

.why-blocks_item h3, .why-blocks_item p {
    z-index: 99;
    position: relative;
}

.why-blocks_item h3 {
    margin: 0 auto 10px auto;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}
.why-blocks_item p {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    /* or 144% */

    text-align: center;
    letter-spacing: 0.03em;

    color: #FFFFFF;
    margin: 0 auto 0 auto;
    padding: 0 15px;
}

.why-blocks_item img {
    position: absolute;
    left: 50%;
    top: -120px;
    width: 231px;
    transform: translate(-50%, 0);
}

.why-blocks_item span {
    position: absolute;
    left: 50%;
    top: -36px;
    width: 231px;
    transform: translate(-50%, 0);
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 60px;
    /* or 120% */

    text-align: center;
    letter-spacing: 0.03em;

    color: #A089CA;
}

.why-1 h3 {
    padding: 20px 30px 0;
}

.why-2 h3 {
    padding: 20px 30px 0;
}

.why-3 h3 {
    padding: 20px 30px 0;
}

.why-1 {
    margin-top: -10px;
}

.why-2 {
    margin-top: 39px;
}

.why-3 {
    margin-top: -10px;
}

@media (max-width: 1160px) {
    .why-1 {
        margin-top: 0;
    }

    .why-2 {
        margin-top: 0;
    }

    .why-3 {
        margin-top: 0;
    }

    .why-blocks_item {
        margin-bottom: 60px;
    }
}
/*.why-1 {*/
/*    background: url("./assets/1.png") no-repeat;*/
/*    background-size: contain;*/
/*}.why-2 {*/
/*    background: url("./assets/2.png") no-repeat;*/
/*    background-size: contain;*/
/*}.why-3 {*/
/*    background: url("./assets/3.png") no-repeat;*/
/*    background-size: contain;*/
/*}*/


/*.why-block_content {*/
/*    width: 100%;*/
/*    background: url("./assets/6.png") no-repeat;*/
/*    background-size: contain;*/
/*    height: 931px;*/
/*}*/

.why-block_bg {
    position: absolute;
    left: calc((100% - 1920px)/2 + 0px);
    top: 50px;
}

.why-block h2 {
    width: 100%;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin-bottom: 80px;
}

@media (max-width: 991px) {
    .why-block h2 {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 40px;
        /* or 133% */

        text-align: center;
        letter-spacing: 0.03em;

        color: #FFFFFF;
    }
}

.why-top_item {
    width: 100%;
    height: 381px;
    background: #142634;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
}

.why-top {
    margin-top: 80px;
    text-align: center;
}

.why-top button {
    margin-top: 60px;
    height: 50px;
}

.why-top h3 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin-bottom: 40px;
}

@media (max-width: 991px) {
    .why-top h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 40px;
        /* or 133% */

        text-align: center;
        letter-spacing: 0.03em;

        color: #FFFFFF;
    }
}