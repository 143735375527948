body {
  background: #142634;
}

h1, h2, h3, h4, h5, h6,
p, span {
  margin: 0;
}

.App {
  overflow: hidden;
}

.button {
  color: #fff !important;
}

.titleBlock {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #FFFFFF;
}

@media (max-width: 1080px) {
  .titleBlock {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    /* or 133% */

    text-align: center;
    letter-spacing: 0.03em;

    color: #FFFFFF;
  }
}

.hidden-desktop-1270 {
  display: none !important;
}

@media (max-width: 1270px) {
  .hidden-desktop-1270 {
    display: flex !important;
  }

  .hidden-mobile-1270 {
    display: none !important;
  }
}

.container {
  max-width: 1300px;
  width: 100%;
  height: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
}

@media (max-width: 1300px) {
  .container {
    width: unset;
  }
}
