.benefits {
    position: relative;
    width: 100%;
    height: 977px;
    padding-top: 70px;
}

.benefits h2 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

@media (max-width: 1080px) {
    .benefits {
        width: 100%;
        height: 100%;
    }

    .benefits h2 {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0.03em;
        color: #FFFFFF;
    }
}

.benefits-content {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(360px,1fr));
    margin-top: 96px;
}

@media (min-width: 320px) and (max-width: 479px) {
    .benefits-content {
        grid-template-columns: repeat(auto-fit,minmax(150px,1fr));
        margin-top: 60px;
    }
}

@media (min-width: 480px) and (max-width: 991px) {
    .benefits-content {
        grid-template-columns: repeat(auto-fit,minmax(240px,1fr));
        margin-top: 76px;
    }
}


.benefits-content_item {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}

.benefits-content_item p {
    width: 408px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    /* or 144% */

    text-align: center;
    letter-spacing: 0.03em;

    color: #FFFFFF;
    margin-top: 15px;
}

@media (max-width: 1080px) {
    .benefits-content_item p {
        width: 100%;
    }
}

.benefits_bg {
    position: absolute;
    left: calc((100% - 1920px)/2 + 0px);
    top: 0;
}

.benefits-timeBlock {
    margin-top: -40px;
}

@media (max-width: 1080px) {
    .benefits-timeBlock {
        margin-top: 50px;
    }
}

.benefits-brainBlock {

}

@media (max-width: 1080px) {
    .benefits-brainBlock {
        margin-top: 50px;
    }
}

.benefits-pazzleBlock {
    margin-top: 42px;
}

@media (max-width: 1080px) {
    .benefits-pazzleBlock {
        margin-top: 50px;
    }
}

.benefits-graphBlock {
    margin-top: 150px;
}

@media (max-width: 1080px) {
    .benefits-graphBlock {
        margin-top: 50px;
    }
}

.benefits-moneyBlock {
    margin-top: 113px;
}

@media (max-width: 1080px) {
    .benefits-moneyBlock {
        margin-top: 50px;
    }

}

.benefits-graphMoneyBlock {
    margin-top: 65px;
}

@media (max-width: 1080px) {
    .benefits-graphMoneyBlock {
        margin-top: 50px;
    }
}