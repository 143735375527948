.service-block {
    position: relative;
    height: 1000px;
    top: -10px;
}

.bg-left {
    left: calc((100% - 1920px)/2 + 0px);
    top: -350px;
    z-index: 1;
    position: absolute;
}

.bg-right {
    left: calc((100% - 0px)/2 + 30px);
    top: -50px;
    position: absolute;
}

.service-block_content {
    position: relative;
    z-index: 999;
    width: 627px;
    padding-top: 90px
}

.service-block_content button {
    height: 50px;
    text-transform: uppercase;
}

.service-block_content h2{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 60px;
    /* or 120% */

    letter-spacing: 0.03em;

    color: #FFFFFF;
    margin-bottom: 10px;
}

@media (max-width: 1014px) {
    .service-block_content h2 {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 60px;
        /* or 200% */

        letter-spacing: 0.03em;

        color: #FFFFFF;
    }

    .service-block_content h4{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 30px;
        /* or 150% */

        letter-spacing: 0.03em;

        color: #FFFFFF;
    }

    .service-block_content p{
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px !important;
        line-height: 24px;
        /* or 150% */

        letter-spacing: 0.03em;

        color: #FFFFFF;
    }

    .service-block_content {
        width: 325px;
    }

    .bg-left {
        left: calc((100% - 1711px)/2 + 0px);
        top: -440px;
    }

    .bg-right {
        top: 110px;
    }

    .bg-el2 {
        position: absolute;
        left: 200px;
        top: 20px;
        z-index: 9;
        transform: rotate(
                285deg
        );
    }

    .bg-el1 {
        position: absolute;
        left: 210px;
        top: 60px;
        z-index: 9;
        transform: rotate(
                -78deg
        );
    }
}

@media (max-width: 425px) {
    .bg-el2 {
        display: block!important;
    }

    .bg-el1 {
        display: block!important;
    }
}

.bg-el2 {
    display: none;
}

.bg-el1 {
    display: none;
}


.service-block_content h4{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */

    letter-spacing: 0.03em;

    color: #FFFFFF;
    margin-bottom: 10px;
}

.service-block_content p{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    /* or 144% */

    letter-spacing: 0.03em;

    color: #FFFFFF;
    margin-bottom: 10px;
}

.service-block_content p:nth-child(5) {
    margin-bottom: 25px;
}

.secure-content_alert {
    background: linear-gradient(180deg, #1A3143 0%, rgba(26, 49, 67, 0) 100%);
    border-radius: 10px;
    height: 153px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 30px;
    position: relative;
    z-index: 9999;
}

@media (max-width: 1014px) {
    .secure-content_alert {
        height: unset;
        padding: 15px 0;
    }
}

.secure-content_alert img {
    width: 31px;
    height: 49px;
    padding-left: 25px;
}

.secure-content_alert p {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding-right: 35px;
}

.secure-content_alert .secure-line {
    transform: rotate(180deg);
    width: 1px;
    height: 100%;
    margin: 0 25px;
}

.secure-content_alert .secure-line .secure-line_child {
    width: 71px;
    transform: rotate(
            90deg
    ) translate(0%);
    top: 50%;
    left: -35px;
}