.header {
    position: relative;
    width: 100%;
    height: 610px;
}

.header_content {
    position: relative;
    z-index: 99;
    padding-top: 111px;
    width: 625px;
}

.header_actions button img {
    margin-top: -4px;
}

.header_actions button {
    height: 50px;
    width: 187px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;

    color: #FFFFFF;
}

.header_actions button:first-child {
    margin-right: 20px;
}

.header_actions button:last-child {
    color: #FFFFFF;
}

.header_content h1 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.header_content h1 span {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.header_content p {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.header_content p:nth-child(3) {
    margin-bottom: 30px;
}

.spotBg, .bgImage {
    position: absolute;
}

.spotBg {
    left: calc((100% - 1920px)/2 + 0px);
    top: -40px;
    z-index: 1;
}

.bgImage {
    left: calc((100% - 190px)/2 + 0px);
    top: -50px;
}

@media (max-width: 1014px) {
    .header_content {
        width: 345px;
        height: 690px;
        padding-top: 80px;
    }

    .header {
        height: 100%;
    }

    .header_actions button:first-child {
        margin-bottom: 20px;
    }

    .header_actions {
        display: flex;
        flex-direction: column;
    }

    .header_content h1 {
        font-family: Roboto, sans-serif;
        font-size: 30px;
    }

    .header_content h1 span {
        font-family: Roboto, sans-serif;
        font-size: 30px;
    }

    .header_content p {
        font-family: Roboto, sans-serif;
        font-size: 16px;
    }

    .spotBg {
        top: 70px;
        left: -653px;
    }

    .bgImage {
        top: 70px;
        left: -120px;
    }
}