.secure {

}

@media (max-width: 1080px) {
  .secure {
    margin-top: 80px;
  }
}

.secure-content {

}

.secure .secure-content_alert {
  background: linear-gradient(180deg, #1A3143 0%, rgba(26, 49, 67, 0) 100%);
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding: 15px 0;
}

@media (max-width: 1080px) {
  .secure .secure-content_alert {
    height: 100%;
    margin-top: 100px;
  }
}

.secure .secure-content_alert img {
  width: 31px;
  height: 29px;
  padding-left: 25px;
}

.secure .secure-content_alert p {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #FFFFFF;
  padding-right: 35px;
}

.secure .secure-content_alert .secure-line {
  transform: rotate(180deg);
  width: 1px;
  height: 100%;
  margin: 0 25px;
}

@media (max-width: 1080px) {
  .secure .secure-content_alert .secure-line {
    height: 200px;
  }
}

.secure .secure-content_alert .secure-line .secure-line_child {
  width: 71px;
  transform: rotate(
          90deg
  ) translate(0%);
  top: 50%;
  left: -35px;
}

.secure-content_items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 28px;
  margin-top: 30px;
}

@media (min-width: 320px) and (max-width: 697px) {
  .secure-content_items {
    grid-template-columns: repeat(auto-fit,minmax(320px,1fr));
    grid-gap: 78px;
  }
}

@media (min-width: 698px) and (max-width: 1120px) {
  .secure-content_items {
    grid-template-columns: repeat(auto-fit,minmax(320px,1fr));
  }
}

.content_item {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(180deg, #1A3143 0%, rgba(26, 49, 67, 0) 100%);
  border-radius: 10px;
  padding: 42px 0 42px 0;
}

@media (max-width: 697px) {
  .content_item {
    display: flex;
    flex-direction: row;
    width: 100%;
    background: linear-gradient(180deg, #1A3143 0%, rgba(26, 49, 67, 0) 100%);
    border-radius: 10px;
    height: 100%;
    padding: 20px 10px 20px 0;
    align-items: center;
  }
}

.content_item img {
  width: 98px;
  height: 98px;
  margin: 0 auto 31px auto;
}

@media (max-width: 697px) {
  .content_item img {
    margin: 0 auto 0 auto;
  }
}

.content_item p {
  width: 344px;
  margin: 25px auto 0 auto;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #FFFFFF;
}

@media (max-width: 697px) {
  .content_item p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-align: left;
    margin: 0;
  }
}

.secure-line {
  position: relative;
  width: 100%;
  height: 1px;
  background: #161C29;
  border-radius: 10px;
}

@media (max-width: 697px) {
  .secure-line {
    transform: rotate(90deg);
    width: unset;
    margin: 0 20px;
  }
}

.secure-line_child {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 190px;
  height: 2px;
  background: #A089CA;
  border-radius: 10px;
  top: -1.6px;
}

@media (max-width: 697px) {
  .secure-line_child {
    width: 80px;
  }
}