.nav-menu {
    position: relative;
    width: 100%;
    height: 65px;
    z-index: 999;
    background: #171F2B;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.nav-menu_content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-menu_list button, .nav-menu_actions button {
  margin-right: 10px;
}

.nav-menu_list button:last-child, .nav-menu_actions button {
    margin-right: 0;
}

.nav-menu_actions button:nth-child(2) {
    margin-right: 0;
}

.nav-menu_actions button:first-child {
    color: #8561c5;
    border: 1px solid rgba(133, 97, 197, 0.5);
}

.nav-menu_logo img {
    width: 48px;
    height: 48px;
    margin-right: 35px;
}

.nav-menu_container {
    display: flex;
    align-items: center;
}


@media (max-width: 1014px) {
    .nav-menu {
        display: none;
    }
}